h1{
    text-align: center;
}
.section
{
    margin-bottom:10px;

    .heading
    {
        font-size:16px;
        font-weight: 600;
        background-color:#f0f0f0;
        border-top: 1px solid #aaa;
        border-left: 1px solid #aaa;
        border-right: 1px solid #aaa;
        padding:5px;
    }

    .content
    {
        border: 1px solid #aaa;
        padding: 5px;
    }    
}
.dropZone
{
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    text-align: center;
    padding: 20px;
}

.submitButton
{
    margin-top:10px;
    margin-bottom:10px;
}